<template>
  <div>
    <h2>1. Общие положения</h2>
    <p>Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального
      закона от 27.07.2006. № 152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и
      меры по обеспечению безопасности персональных данных Информационно-аналитической системы ИМПАКТ (далее —
      Оператор).</p>
    <ol>
      <li>Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и
        свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на
        неприкосновенность частной жизни, личную и семейную тайну.</li>
      <li>Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко
        всей информации, которую Оператор может получить о посетителях информационного ресурса
        https://impact.ru.com/.</li>
      <li>Мы обрабатываем те или иные категории Персональных данных в зависимости от того, каким образом Вы
        пользуетесь нашими услугами.</li>
      <li>Все Персональные данные обрабатываются в соответствии с применимым законодательством в сфере защиты
        данных.</li>
      <li>Мы передаем Ваши Персональные данные исключительно привлекаемым нами обработчикам (т.е. третьим лицам,
        действующим по нашему поручению), которые содействуют нам при оказании услуг, а также уполномоченных
        государственных органов. На основании Вашего согласия мы можем использовать cookies для маркетинговых
        целей, а также улучшения функциональности наших сервисов и также в целях статистического анализа.</li>
    </ol>
    <h2>Принципы обработки Персональных данных</h2>
    <p>Мы ответственно относимся к вопросам конфиденциальности и защиты Персональных данных. Ниже представлены
      ключевые правила, которых мы придерживаемся при обработке Ваших персональных данных:</p>
    <ul>
      <li>Мы всегда отвечаем на любые вопросы, касающиеся обработки ваших Персональных данных.</li>
      <li>Мы всегда обеспечиваем использование Ваших Персональных данных в соответствии с требованиями
        законодательства РФ.</li>
      <li>Вы всегда можете обратиться к нам в случае возникновения у Вас каких-либо вопросов.</li>
      <li>Мы предоставляем одинаковый уровень защиты данных, собираемых и обрабатываемых как в сети «Интернет»,
        так и не в сети «Интернет».</li>
    </ul>
    <h2>3. Основные понятия, используемые в Политике</h2>
    <ol>
      <li>Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств
        вычислительной техники.
      </li>
      <li>Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением
        случаев, если обработка необходима для уточнения персональных данных).
      </li>
      <li>Информационный ресурс — совокупность графических и информационных материалов, а также программ для ЭВМ и
        баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://impact.ru.com</a>.
      </li>
      <li>Информационная система персональных данных — совокупность содержащихся в базах данных персональных
        данных, и обеспечивающих их обработку информационных технологий, и технических средств.</li>
      <li>Обезличивание персональных данных — действия, в результате которых невозможно определить без
        использования дополнительной информации принадлежность персональных данных конкретному Пользователю или
        иному субъекту персональных данных.</li>
      <li>Обработка персональных данных — любое действие (операция) или совокупность действий (операций),
        совершаемых с использованием средств автоматизации или без использования таких средств с персональными
        данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
        извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
        блокирование, удаление, уничтожение персональных данных.</li>
      <li>Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно
        или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также
        определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке,
        действия (операции), совершаемые с персональными данными.</li>
      <li>Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому
        Пользователю информационного ресурса https://impact.ru.com.</li>
      <li>Пользователь — любой посетитель информационного ресурса https://impact.ru.com/.</li>
      <li>Предоставление персональных данных — действия, направленные на раскрытие персональных данных
        определенному лицу или определенному кругу лиц.</li>
      <li>Распространение персональных данных — любые действия, направленные на раскрытие персональных данных
        неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными
        неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации,
        размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным
        каким-либо иным способом.</li>
      <li>Трансграничная передача персональных данных — передача персональных данных на территорию иностранного
        государства органу власти иностранного государства, иностранному физическому или иностранному юридическому
        лицу.</li>
      <li>Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются
        безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной
        системе персональных данных и (или) результате которых уничтожаются материальные носители персональных
        данных.</li>
    </ol>
    <h2>4. Оператор может обрабатывать следующие персональные данные Пользователя</h2>
    <ul>
      <li>Фамилия, имя, отчество; номера телефонов; адрес электронной почты, а также иные данные, необходимые для
        выполнения нами своих обязательств по заключенным договорам.</li>
      <li>Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с
        помощью сервисов интернет-статистики.</li>
      <li>Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</li>
    </ul>
    <h2>5. Цели обработки персональных данных</h2>
    <ol>
      <li>Цель обработки персональных данных Пользователя:
        <ul>
          <li>заключение, исполнение и прекращение гражданско-правовых договоров.</li>
          <li>предоставление сервисов (функционала) информационного ресурса ИМПАКТ в соответствии с Вашими
            предпочтениями, обработки Ваших запросов, для связи с Вами по вопросам продуктов и услуг, которые
            могут представлять для Вас интерес, а также решения соответствующих вопросов организационного
            (административного) характера.</li>
        </ul>
      </li>
      <li>Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных
        предложениях и различных событиях.
      </li>
      <li>Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора
        информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.
      </li>
      <li>Пользуясь нашими услугами и/или сайтом, Вы соглашаетесь с обработкой Ваших Персональных данных. В
        случае, если Вы не желаете, чтобы мы осуществляли обработку Ваших Персональных данных, пожалуйста, не
        используйте наши услуги или сайт или не предоставляйте каким-либо иным образом Ваши Персональные данные.
      </li>
      <li>Мы обрабатываем Ваши данные для целей предоставления Вам наших услуг и сервисов (функционала)
        информационного ресурса.</li>
    </ol>
    <h2>6. Правовые основания обработки персональных данных</h2>
    <p>Оператор обрабатывает персональные данные Пользователя только в случае:</p>
    <ul>
      <li>Их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на
        информационном ресурсе https://impact.ru.com/.</li>
      <li>Заключение различных гражданско-правовых договоров.</li>
    </ul>
    <p>Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает
      свое согласие с данной Политикой. Оператор обрабатывает обезличенные данные о Пользователе в случае, если
      это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование
      технологии JavaScript).</p>
    <h2>7. Порядок сбора, хранения, передачи и других видов обработки персональных данных</h2>
    <p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации
      правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований
      действующего законодательства в области защиты персональных данных.</p>
    <ol>
      <li>Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ
        к персональным данным неуполномоченных лиц.</li>
      <li>Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за
        исключением случаев, связанных с исполнением действующего законодательства.</li>
      <li>В случае выявления неточностей в персональных данных, Пользователь может актуализировать их
        самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора
        contact@impact.ru.com с пометкой «Актуализация персональных данных».</li>
      <li>Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать
        свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной
        почты на электронный адрес Оператора contact@impact.ru.com с пометкой «Отзыв согласия на обработку
        персональных данных».</li>
    </ol>
    <h2>8. Трансграничная передача персональных данных</h2>
    <ol>
      <li>Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что
        иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных,
        обеспечивается надежная защита прав субъектов персональных данных.
      </li>
      <li>Трансграничная передача персональных данных на территории иностранных государств, не отвечающих
        вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме
        субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора,
        стороной которого является субъект персональных данных.
      </li>
    </ol>
    <h2>9. Заключительные положения</h2>
    <ol>
      <li>Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его
        персональных данных, обратившись к Оператору с помощью электронной почты contact@impact.ru.com.
      </li>
      <li>В данном документе будут отражены любые изменения политики обработки персональных данных Оператором.
        Политика действует бессрочно до замены ее новой версией.
      </li>
      <li>Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу
        Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу
        https://impact.ru.com/policy.html.
      </li>
    </ol>
  </div>
</template>

<script>
export default {
name: 'PrivacyPolicy',
};
</script>

<style scoped>

</style>