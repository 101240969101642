
import { Component } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { PURGE_FILTER_VALUES } from '@/store/mutations.type';
import { i18n } from '@/translations';
import FilterForm from '@/components/Analysis/FilterForm.vue';
import FilterTags from '@/components/Analysis/FilterTags.vue';
import IncidentsList from '@/components/IncidentsList.vue';
import IncidentView from '@/components/IncidentView.vue';
import IncCategoryBox from '@/components/IncCategoryBox.vue';
import GoogleMap from '@/components/GoogleMap.vue';
import { mixins } from 'vue-class-component';
import IncidentsMapMixin from '@/mixins/IncidentsMapMixin';
import ExportButton from '@/components/ExportButton.vue';


@Component({
  components: { ExportButton, FilterForm, FilterTags, IncidentsList, IncidentView, GoogleMap, IncCategoryBox },
  computed: mapGetters([
    'filterFormData', 'summary', 'filterRefs',
  ]),
})

export default class IncidentsPageNew extends mixins(IncidentsMapMixin) {
  private showFilter = false;
  private showIncidentView = false;
  private tagsLocation: string = 'filterForm';
  private initLoad: boolean = false;
  private filterFormData!: any;

  public async mounted() {
    this.$store.commit(PURGE_FILTER_VALUES, '');
    this.initLoad = true;
  }

  get filterSelectData() {
    const res: any = {category: {refsKey: 'incident_category',
                                 translateKey: 'strings.undefined',
                                 valueKey: 'categoryVal',
                                 options: []}};
    const capitalize = ([first, ...rest]: any) => first.toUpperCase() + rest.join('');
    Object.keys(res).map( (optionKey: any) => {
      if (res[optionKey].refsKey in this.filterRefs) {
        this.filterRefs[res[optionKey].refsKey].map( (row: any) => {
          res[optionKey].options.push({value: row.value,
                                       text: capitalize(row[`name_${i18n.locale}`])});
        });
      }
    });
    return res;
  }

  private showFilterForm() {
    this.showIncidentView = false;
    this.showFilter = !this.showFilter;
  }

  private showIncident(incidentId: number) {
    const incidentView: any = this.$refs.incidentView;
    incidentView.loadIncident(incidentId);
    this.showIncidentView = true;
  }
}
