
  import { Vue, Component } from 'vue-property-decorator';
  import PrivacyPolicy from '@/components/PrivacyPolicy.vue';

  @Component({
    components: { PrivacyPolicy },
  })
  export default class PolicyPage extends Vue {

  }
