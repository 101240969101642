
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { FETCH_INCIDENT, CREATE_INCIDENT, UPDATE_INCIDENT, FETCH_INCIDENT_REFERENCES } from '../store/actions.type';
    import { mapGetters } from 'vuex';
    import { convertDate, convertDateToBackend, convertTimeToBackend } from '@/common/helpers';
    import DatePicker from 'vue2-datepicker';

    @Component({
        components: {DatePicker},
        computed: mapGetters(['incident', 'isLoading', 'references', 'errors']),
    })
    export default class IncidentForm extends Vue {

        @Prop()
        public incidentId!: string;
        public incident!: any;
        public errors!: any;
        public references!: object;
        public showError: boolean = false;
        public createNew: boolean = false;
        public formSubmit: boolean = false;

        public formData = {
            incident_date: '',
            incident_time: '',
            members_registered_at_exchange: null,
            mentioned_in_media_year: '',
            mentioned_in_media_month: '',
            cargo_amount: '',
            description_ru: '',
            city_ru: '',
            street: '',
            road: '',
            post_code: '',
            longitude: '',
            latitude: '',
            point_of_departure_ru: '',
            destination_ru: '',
            have_vehicle_info: null,
            have_ogrn: null,
            tractor_license_plate: '',
            tractor_vin: '',
            trailer_license_plate: '',
            trailer_vin: '',
            have_driver_id: null,
            participant_in_court_proceedings: null,
            have_driver_photo: null,
            controlrisk_id: '',
            cargo_price_rub: '',
            incident_category: '',
            attack_method: null,
            cargo_category: null,
            cargo_description: null,
            country: null,
            information_source: null,
            region: null,
            type_of_scene: null,
        };

        public async mounted() {
            await this.$store.dispatch(FETCH_INCIDENT_REFERENCES);
            if (this.incidentId === 'new') {
                this.createNew = true;
            } else {
                this.createNew = false;
                await this.$store.dispatch(FETCH_INCIDENT, this.incidentId);
                this.formData = this.formatIncident;
            }
        }

        get yesNoValues() {
            return [
                {value: null, text: '—'},
                {value: true, text: this.$t('strings.yes')},
                {value: false, text: this.$t('strings.no')},
            ];
        }

        get formatIncident() {
            const tmp: any = Object.assign({}, this.incident);
            Object.keys(tmp).map( (item) => {
                tmp[item] = this.propI18n(tmp[item]);
            });
            tmp.incident_date = tmp.incident_date ? new Date(tmp.incident_date) : '';
            tmp.incident_time = tmp.incident_time ? new Date('1970-01-01T' + tmp.incident_time) : '';
            tmp.mentioned_in_media_year = tmp.mentioned_in_media_year ? new Date(String(tmp.mentioned_in_media_year)) : '';
            tmp.mentioned_in_media_month = new Date(tmp.mentioned_in_media_month &&
            String(tmp.mentioned_in_media_month).length === 1 ? `0${tmp.mentioned_in_media_month}`
                : tmp.mentioned_in_media_month);
            tmp.coordinates = tmp.latitude && tmp.longitude ? `${tmp.latitude}° ${tmp.longitude}°` : '';
            ['attack_method', 'cargo_category', 'cargo_description', 'country', 'incident_category',
                'information_source', 'region', 'type_of_scene'].map( (field) => {
                    tmp[field] = tmp[field] ? tmp[field].id : null;
            });
            return tmp;
        }

        public async submitForm() {
            const preparedData: any = {...this.formData};
            preparedData.mentioned_in_media_year = preparedData.mentioned_in_media_year ?
                preparedData.mentioned_in_media_year.getFullYear() : preparedData.mentioned_in_media_year;
            if (preparedData.mentioned_in_media_month) {
                preparedData.mentioned_in_media_month = preparedData.mentioned_in_media_month.getMonth() + 1;
                if (preparedData.mentioned_in_media_month.length === 1) {
                    preparedData.mentioned_in_media_month = '0' + preparedData.mentioned_in_media_month;
                }
            }
            preparedData.incident_date = preparedData.incident_date ?
                convertDateToBackend(preparedData.incident_date) : '';
            preparedData.incident_time = preparedData.incident_time ?
                convertTimeToBackend(preparedData.incident_time) : '';
            Object.keys(this.formData).map( (field) => {
                if (preparedData[field] === null || !String(preparedData[field]).length) {
                    delete preparedData[field];
                }
            } );

            this.formSubmit = true;
            if (this.createNew) {
                await this.$store.dispatch(CREATE_INCIDENT, preparedData);
            } else {
                await this.$store.dispatch(UPDATE_INCIDENT, preparedData);
            }
            if (this.errors !== null) {
                const text = this.errors && this.errors.status === 400 ?
                    'Заполните обязательные поля.' : 'Извините, возникла ошибка.';
                await this.$bvModal.msgBoxOk(text, {
                    okVariant: 'danger',
                    okTitle: 'Ок',
                    centered: true,
                });
                this.formSubmit = false;
            } else {
                await this.$bvModal.msgBoxOk('Информация успешно сохранена.', {
                    title: 'Сохранено',
                    okVariant: 'success',
                    okTitle: 'Ок',
                    centered: true,
                });
                this.formSubmit = false;
                this.$router.push({ path: `/incidents/view/${this.incident.id}` });
            }

        }

        public amountFormat(value: string, event: any) {
            let newVal = value.replace(/[^0-9.]/g, '');
            let testSplit = newVal.split('.');
            if (testSplit.length > 2) {
                testSplit = testSplit.slice(0, 2);
                newVal = testSplit.join('.');
            }
            if (testSplit.length === 2) {
                if (testSplit[0].length > 4) {
                    newVal = `${testSplit[0].slice(0, 4)}.${testSplit[0].slice(4)}`;
                }
                if (testSplit[1].length > 1) {
                    newVal = `${testSplit[0]}.${testSplit[1].slice(1)}`;
                }
            } else if (newVal.length > 4) {
                newVal = newVal.slice(0, 4);
            }
            return newVal;
        }

        private propI18n(prop: any) {
            const locale = this.$i18n.locale;
            let propVal = prop;
            if (prop && prop.hasOwnProperty('name_ru')) {
                propVal = {id: prop.id, title: prop['name_' + locale]};
            }
            return propVal;
        }

        get monthFormat() {
            return {
                stringify: (date: any): any => {
                    return date ? this.$t(`months[${date.getMonth() + 1}]`) : '';
                },
                parse: (value: any): any => {
                    return value ? value : '';
                },
            };
        }


    }
