
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { FETCH_INCIDENT } from '../store/actions.type';
    import { mapGetters } from 'vuex';
    import { convertDate } from '@/common/helpers';
    import { markerIcons } from '@/common/markerIcons';

    @Component({
        computed: mapGetters(['incident', 'isLoading', 'currentUser']),
    })
    export default class IncidentViewOld extends Vue {

        @Prop()
        public incidentId!: string;
        public incident!: object;


        public async mounted() {
            await this.$store.dispatch(FETCH_INCIDENT, this.incidentId);
        }

        get formatIncident() {
            const tmp: any = Object.assign({}, this.incident);
            Object.keys(tmp).map( (item) => {
                tmp[item] = this.propI18n(tmp[item]);
            });
            tmp.date = tmp.incident_date ?
                `${convertDate(tmp.incident_date)} ${tmp.incident_time ? tmp.incident_time : ''}` :
                this.$t('strings.undefined');
            tmp.mentioned_in_media_date = [tmp.mentioned_in_media_month ?
                this.$t(`months[${tmp.mentioned_in_media_month}]`) : '', tmp.mentioned_in_media_year]
                .filter( (item) => item).join(' ');
            tmp.cargo_price = [
                tmp.cargo_price_rub ? tmp.cargo_price_rub + '₽' : '',
                tmp.cargo_price_eur ? tmp.cargo_price_eur + '€' : '',
                tmp.cargo_price_usd ? tmp.cargo_price_usd + '$' : '',
            ].filter( (item) => item).join('; ');
            tmp.coordinates = tmp.latitude && tmp.longitude ? `${tmp.latitude}° ${tmp.longitude}°` : '';
            ['have_vehicle_info', 'have_ogrn', 'have_driver_id', 'participant_in_court_proceedings',
                'members_registered_at_exchange', 'have_driver_photo'].map( (item) => {
                tmp[item] = tmp[item] ? this.$t('strings.yes') : this.$t('strings.no');
            });
            ['tractor_license_plate',  'tractor_vin', 'trailer_license_plate', 'trailer_vin'].map( (item) => {
                tmp[item] = tmp[item] === 'КР' ? this.$t('strings.yes') : tmp[item];
            });
            tmp.mapIcon = markerIcons.hasOwnProperty(tmp.attack_method) ? markerIcons[tmp.attack_method]
                : markerIcons[0];
            return tmp;
        }

        private propI18n(prop: any) {
            const locale = this.$i18n.locale;
            let propVal = prop;
            if (prop && prop.hasOwnProperty('name_ru')) {
                propVal = {id: prop.id, title: prop['name_' + locale]};
            }
            return propVal;
        }
    }
