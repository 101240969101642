
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class FAQ extends Vue {
  public activeQ1: boolean = false;
  public activeQ2: boolean = false;
  public activeQ3: boolean = false;
  public activeQ4: boolean = false;
  public activeQ5: boolean = false;
  public activeQ6: boolean = false;
  public activeQ7: boolean = false;
  public activeQ8: boolean = false;
}
