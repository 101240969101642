export const markerIcons: any = {
    0: require('@/assets/icons/undefined_marker.png'),
    1: require('@/assets/icons/attack_method_marker_1.png'),
    2: require('@/assets/icons/attack_method_marker_2.png'),
    3: require('@/assets/icons/attack_method_marker_3.png'),
    4: require('@/assets/icons/attack_method_marker_4.png'),
    5: require('@/assets/icons/attack_method_marker_5.png'),
    6: require('@/assets/icons/attack_method_marker_6.png'),
    7: require('@/assets/icons/attack_method_marker_7.png'),
    8: require('@/assets/icons/attack_method_marker_11.png'),
};
