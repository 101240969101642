
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { SET_FILTER_VALUES, PREPARE_FILTER_REQUEST } from '@/store/mutations.type';
import { FETCH_COORDINATES } from '@/store/actions.type';
import vuescroll from 'vuescroll';

interface Option {
  text: string | number;
  value: string | number;
}

@Component({
  components: { vuescroll },
  computed: mapGetters([
    'loadingCoordinates', 'filterRequest',
  ]),
})

export default class IncCategoryBox extends Vue {

  @Prop({default: 198})
  private maxHeightOptions!: number;

  @Prop({ default: [] })
  private options!: Option[];

  @Prop({ default: [0, 1, 2, 3] })
  private value!: any;

  @Prop({ default: false })
  private fullBorder!: boolean;

  private currentValues: any = [0, 1, 2, 3];
  private fullValues: any = [0, 1, 2, 3];
  private currentValue: any = '';
  private firstTouch: boolean = true;

  private selectOpen: boolean = false;
  private loadingCoordinates!: boolean;
  private filterRequest!: any;
  private showLegend: boolean = true;
  private colors: string[] = ['#FF2D55', '#AC2C45', '#5A0213', '#FFADBC'];

  private scrollOptions = {
    rail: {
      opacity: '0.1',
      background: '#000000',
      size: '4px',
      gutterOfSide: '16px',
      gutterOfEnds: '8px',
    },
    bar: {
      background: '#007AFF',
      keepShow: true,
      specifyBorderRadius: '4px',
      size: '4px',
      minSize: 0.1,
    },
  };

  get dropdownHeightLimit() {
    return this.options.length > 5;
  }

  private closeSelect() {
    this.selectOpen = false;
  }

  @Watch('value')
  private onValueChange() {
    if (!this.loadingCoordinates) {
      if (this.firstTouch) {
        this.firstTouch = false;
      }
      if (this.value.length === 0 ||
          this.value[0] === -1) {
        this.currentValues = [...this.fullValues];
      } else {
        this.currentValues = [...this.value];
      }
    }
  }

  private isChecked(value: number) {
    let result = false;
    if (this.currentValues.includes(value) ||
        this.currentValues.length === 0 ||
        (this.currentValues.length === 1 &&
         this.currentValues[0] === -1)) {
      result = true;
    }
    return result;
  }

  private addValueToCurrentValues(value: number) {
    const defaultValue: number = -1;
    if (!this.currentValues.includes(value)) {
      if (value === defaultValue) {
        this.currentValues = [];
      } else if (this.currentValues.includes(defaultValue)) {
        const elemIndex = this.currentValues.indexOf(defaultValue);
        if (elemIndex > -1) {
          this.currentValues.splice(elemIndex, 1);
        }
      }
      this.currentValues.push(value);
    } else {
      const elemIndex = this.currentValues.indexOf(value);
      if (elemIndex > -1) {
        this.currentValues.splice(elemIndex, 1);
      }
    }
  }

  private async selectVal(value: number) {
    if (!this.loadingCoordinates) {
      this.addValueToCurrentValues(value);
      this.$emit('input', [...this.currentValues]);
      this.$store.commit(SET_FILTER_VALUES);
      this.$store.commit(PREPARE_FILTER_REQUEST);
      await this.$store.dispatch(FETCH_COORDINATES, this.filterRequest);
    }
  }

  get allowFullBorderClass() {
    let allowed: boolean = false;
    if (this.fullBorder) {
      allowed = true;
    }
    return allowed;
  }

  get formattedOptions() {
    const options: Option[] = this.options.map((opt: Option) => ({ ...opt }));
    return options;
  }
}
