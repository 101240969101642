
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { FETCH_INCIDENTS, EXPORT_INCIDENTS,
             DELETE_INCIDENT, FETCH_INCIDENTS_SUMMARY,
             FETCH_COORDINATES, FETCH_REFERENCES } from '@/store/actions.type';
    import { PREPARE_FILTER_REQUEST, SET_INCIDENTS_COUNT } from '@/store/mutations.type';
    import { mapGetters } from 'vuex';
    import { i18n } from '@/translations';
    import { convertDate, propI18n, numberWithCommas } from '@/common/helpers';
    import Spinner from '@/components/Spinner.vue';


    @Component({
        components: { Spinner },
        computed: mapGetters(['incidentsCount',
                              'fileLoading', 'loadingCoordinates',
                              'currentUser', 'filterFormData',
                              'filterRequest', 'refs',
                              'coordinates', 'mapBounds']),
    })
    export default class IncidentsList extends Vue {

        public incidentsCount!: number;
        public currentUser!: any;
        public deleteId: boolean | null = null;
        public pageParams: {
            page: number,
            sortBy: string,
            sortDesc: boolean,
        } = {
            page: 1,
            sortBy: 'incident_date',
            sortDesc: true,
        };

        public sortBy: string = 'incident_date';
        public sortDesc: boolean = true;
        // @Prop({default: {}})
        public filter: any = {};

        @Prop({default: false})
        public loadDataOnActive!: boolean;
        private loadingCoordinates!: boolean;
        private filterFormData!: any;
        private filterRequest!: any;
        private refs!: any;
        private coordinates!: any;
        private mapBounds!: any;
        private listedIncidentsOnPage: number = 10;
        private okToLoadNext: boolean = false;
        private currentIncidents: any = [];
        private containerClass: string = 'c--sidebar-cards-list__container';
        private targetClass: string = 'list__loader';
        private elementsClass: string = 'c--sidebar-card';
        private loaderObserver: any;
        private observerOptions: any = {
          root: document.querySelector(`.${this.containerClass}`),
          rootMargin: '0px',
          threshold: 0.5,
        };


        // @Watch('$route', { immediate: true, deep: true })
        // public onUrlChange(newVal: any) {
        //     if (this.pageParams.page !== newVal.params.page) {
        //         this.pageParams.page = newVal.params.page;
        //         this.getIncidents();
        //     }
        // }

        private async mounted() {
          await this.$store.dispatch(FETCH_REFERENCES);
          await this.$store.dispatch(FETCH_COORDINATES);
          if (this.coordinates) {
            this.setCurrentIncidents();
          }
          this.loaderObserver = new IntersectionObserver(this.loadNextChunk,
                                                         this.observerOptions);
          const target = document.querySelector(`.${this.targetClass}`);
          this.loaderObserver.observe(target);
          this.okToLoadNext = true;
        }

        private loadNextChunk(entries: any, observer: any) {
          entries.map( (entry: any) => {
            if (entry.isIntersecting && this.okToLoadNext) {
              this.listedIncidentsOnPage += 10;
              this.setCurrentIncidents();
            }
          });
        }

        @Watch('coordinates')
        private onCoordinatesChange() {
          this.listedIncidentsOnPage = 10;
          this.setCurrentIncidents();
        }

        @Watch('mapBounds', { deep: true })
        private onMapBoundsChange() {
          this.setCurrentIncidents();
        }

        private setCurrentIncidents() {
          this.currentIncidents = this.coordinates.filter((item: any) => {
            return item.position.lat >= this.mapBounds.south && item.position.lat <= this.mapBounds.north
              && item.position.lng >= this.mapBounds.west && item.position.lng <= this.mapBounds.east;
          }).slice(0, this.listedIncidentsOnPage);
        }

        private getLocation(item: any) {
          let districtId: number = 0;
          let districtName: string = '';
          let location: any;
          let region: any;
          const cityName: any = item.city[`name_${i18n.locale}`];
          if ('region_id' in item && item.region_id) {
            region = this.refs.region[item.region_id];
            if ('federal_district' in region) {
              districtId = region.federal_district;
            }
          }
          if (districtId) {
            const fDistrict: any = this.refs.federal_district[districtId];
            const prefix: string = this.$i18n.locale === 'ru' ? 'ФО' : 'FD';
            const localeKey: string = `name_${i18n.locale}`;
            const newLabel = (fDistrict[localeKey].indexOf(' ') > -1) ?
                             fDistrict[localeKey].split(' ') :
                             fDistrict[localeKey].split('-');
            districtName = `${newLabel.map((word: any) => word[0]).join('')}${prefix}`;
          }
          if (!districtName && !cityName) {
            location = this.$t('strings.undefinedLocation');
          } else {
            if (districtName) {
              location = [districtName, cityName].join(', ');
            } else {
              location = cityName;
            }
          }
          return location;
        }

        private setListedIncident(item: any, index: number) {
          const priceValue: any = (item.cargo_price_rub) ?
                                  `${numberWithCommas(item.cargo_price_rub)} ₽` :
                                  this.$t('strings.undefined');
          const incidentDate: any = item.incident_date ?
                                    `${convertDate(item.incident_date)}` :
                                    this.$t('strings.undefined');
          const incident: any = {index,
                                 id: item.id,
                                 location: this.getLocation(item),
                                 priceValue,
                                 incident_date: incidentDate};
          const keys: any = ['incident_category', 'cargo_category'];
          keys.map( (key: string) => {
            if (item[`${key}_id`]) {
              const keyObj = this.refs[key][item[`${key}_id`]];
              incident[key] = keyObj[`name_${this.$i18n.locale}`];
            } else {
              incident[key] = this.$t('strings.undefined');
            }
          });
          return incident;
        }

        get formattedIncidents() {
            return this.currentIncidents.map( (item: any, index: number) => {
                return this.setListedIncident(item, index);
            });
        }

        private viewIncident(item: any, index: number, event: any) {
            if (typeof event.target.cellIndex === 'undefined' || event.target.cellIndex === 6) {
                return;
            }
            window.open(`/incidents/view/${item.id}`, '_blank');
        }

        private editIncident(item: any) {
            window.open(`/incidents/edit/${item.id}`, '_blank');
        }

        private showDeleteModal(item: any) {
            this.deleteId = item.id;
            this.$nextTick(() => {
                this.$root.$emit('bv::show::modal', String(this.deleteId));
            });
        }

        private async deleteIncident(bvModalEvt: any) {
            bvModalEvt.preventDefault();
            await this.$store.dispatch(DELETE_INCIDENT, this.deleteId);
            this.$root.$emit('bv::hide::modal',  String(this.deleteId));
        }

        private resetCreateModal() {
            this.deleteId = null;
        }

    }
