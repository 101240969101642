
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import IncidentForm from '@/components/IncidentForm.vue';
    import IncidentViewOld from '@/components/IncidentViewOld.vue';
    import { mapGetters } from 'vuex';

    @Component({
        components: { IncidentForm, IncidentViewOld },
        computed: mapGetters(['currentUser']),
    })
    export default class IncidentPage extends Vue {

        @Prop()
        private edit!: boolean;
        private currentUser!: any;
        private incidentId: string = '';

        public async mounted() {
            this.incidentId = this.$route.params.id;
        }

        @Watch('$route')
        public updateId() {
            this.incidentId = this.$route.params.id;
        }

        public goBack() {
            if (window.history.length === 1) {
                close();
            } else {
                window.history.go(-1);
            }
        }

    }
